module.exports = [{
      plugin: require('/home/travis/build/deepstreamIO/deepstream.io-website/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/travis/build/deepstreamIO/deepstream.io-website/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-63583386-1","head":false,"anonymize":true,"exclude":[],"pageTransitionDelay":0,"cookieDomain":"deepstream.io"},
    },{
      plugin: require('/home/travis/build/deepstreamIO/deepstream.io-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
