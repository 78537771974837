// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-tsx": () => import("/home/travis/build/deepstreamIO/deepstream.io-website/src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-docs-tsx": () => import("/home/travis/build/deepstreamIO/deepstream.io-website/src/templates/docs.tsx" /* webpackChunkName: "component---src-templates-docs-tsx" */),
  "component---src-templates-guides-tsx": () => import("/home/travis/build/deepstreamIO/deepstream.io-website/src/templates/guides.tsx" /* webpackChunkName: "component---src-templates-guides-tsx" */),
  "component---src-templates-info-tsx": () => import("/home/travis/build/deepstreamIO/deepstream.io-website/src/templates/info.tsx" /* webpackChunkName: "component---src-templates-info-tsx" */),
  "component---src-templates-tutorials-tsx": () => import("/home/travis/build/deepstreamIO/deepstream.io-website/src/templates/tutorials.tsx" /* webpackChunkName: "component---src-templates-tutorials-tsx" */),
  "component---src-pages-404-tsx": () => import("/home/travis/build/deepstreamIO/deepstream.io-website/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("/home/travis/build/deepstreamIO/deepstream.io-website/src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-docs-index-tsx": () => import("/home/travis/build/deepstreamIO/deepstream.io-website/src/pages/docs/index.tsx" /* webpackChunkName: "component---src-pages-docs-index-tsx" */),
  "component---src-pages-guides-index-tsx": () => import("/home/travis/build/deepstreamIO/deepstream.io-website/src/pages/guides/index.tsx" /* webpackChunkName: "component---src-pages-guides-index-tsx" */),
  "component---src-pages-index-tsx": () => import("/home/travis/build/deepstreamIO/deepstream.io-website/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tutorials-index-tsx": () => import("/home/travis/build/deepstreamIO/deepstream.io-website/src/pages/tutorials/index.tsx" /* webpackChunkName: "component---src-pages-tutorials-index-tsx" */)
}

